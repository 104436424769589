import { AxiosResponse } from 'axios'
import urlJoin from 'url-join'
import {
  ACCOUNT_ORDERS_ROUTE,
  CART_ROUTE,
  RETURNS_ROUTE,
} from '@services/cart/routes'
import {
  CUSTOMER_ADDRESSES_ROUTE,
  CUSTOMER_RESET_PASSWORD_ROUTE,
  CUSTOMER_ROUTE,
  CUSTOMER_VERIFY_EMAIL,
  CUSTOMER_WISHLIST_ROUTE,
  LOGIN_ROUTE,
  REFRESH_TOKEN_ROUTE,
} from '@services/customer/routes'
import { ORDERS_PER_PAGE } from '@lib/constants/orders'
import { getAuthState } from '@lib/handlers/auth'
import {
  ChangeUserPassword,
  CustomerOrdersRaw,
  CustomerReturnsRaw,
  ForgotPasswordBody,
  InventoryResource,
  ResetPasswordBody,
  ReturnableItem,
  ReturnItem,
  TAddress,
  TAddressForm,
  TAuthenticationToken,
  TCreateCustomerBody,
  TCustomer,
  Wishlist,
  WishlistUpdateBody,
} from '@lib/types'
import {
  CreateReturnForm,
  LocationMethods,
  ReturnDeliveryPoint,
  TRefreshToken,
} from '@lib/types/customer'
import { ILoginFormValues } from '@lib/types/signup'
import { axiosInstance } from '@lib/utils/axios'
import parseRawCustomerOrdersData from '@lib/utils/customer/parseRawCustomerOrdersData'
import parseRawCustomerReturnsData from '@lib/utils/customer/parseRawCustomerReturnsData'

// TODO: update with refreshToken from schema when available
interface IAuthenticationTokenBad extends TAuthenticationToken {
  token: string
  customer: string
  refreshToken: string
}

interface ChangeCustomerPasswordParams extends ChangeUserPassword {
  customerId?: string | number
}

export const loginCustomer = async (body: ILoginFormValues) => {
  const response = await axiosInstance.post<IAuthenticationTokenBad>(
    LOGIN_ROUTE,
    body
  )

  return response.data
}

export const createCustomer = async (body: TCreateCustomerBody) => {
  await axiosInstance.post(CUSTOMER_ROUTE, body)
}

// TODO: Use axios instance and pass a param to ignore refetching if the request fails
export const getRefreshToken = async (
  body: TRefreshToken
): Promise<TRefreshToken | null> => {
  try {
    const response = await fetch(
      urlJoin(process.env.ADEOWEB_SYLIUS_API_URL ?? '', REFRESH_TOKEN_ROUTE),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        next: {
          revalidate: 0,
        },
        body: JSON.stringify(body),
      }
    )

    if (!response.ok) {
      return null
    }

    return await response.json()
  } catch {
    return null
  }
}

export const getCustomer = async (): Promise<TCustomer | null> => {
  const { customerId } = getAuthState()

  try {
    if (customerId) {
      const { data } = await axiosInstance.get(
        `${CUSTOMER_ROUTE}/${customerId}`
      )
      return data
    }

    return null
  } catch (error) {
    return null
  }
}

export const getReturnableItems = async (token?: string) => {
  if (!token) {
    throw new Error('Order token was not provided')
  }

  const response = await axiosInstance.get<ReturnableItem[]>(
    urlJoin(ACCOUNT_ORDERS_ROUTE, token, 'returnable-item-units')
  )

  return response.data
}

export const getInventoryResources = async ({
  isPickupEnabled,
  isReturnsEnabled,
}: {
  isReturnsEnabled?: boolean
  isPickupEnabled?: boolean
}) => {
  try {
    const response: AxiosResponse<InventoryResource[]> =
      await axiosInstance.get(
        urlJoin(
          '/shop/inventory-sources',
          '?itemsPerPage=200',
          isPickupEnabled ? '&isPickupEnabled=true' : '',
          isReturnsEnabled ? '&isReturnsEnabled=true' : ''
        )
      )

    return response.data
  } catch (error) {
    return null
  }
}

export const createReturn = async (
  token: string | undefined,
  values: CreateReturnForm,
  delivery: ReturnDeliveryPoint,
  paidOnGateway?: boolean | null
) => {
  const { contacts, items } = values

  try {
    if (!token) {
      throw new Error('Order token was not provided.')
    }

    const response: AxiosResponse<ReturnableItem[]> = await axiosInstance.patch(
      urlJoin(ACCOUNT_ORDERS_ROUTE, token, 'request-return'),
      {
        resolution: 'return',
        returnedUnits: items
          .filter((item) => item.isSelected)
          .map(({ isSelected, ...rest }) => ({
            ...rest,
          })),
        shippingMethodType:
          delivery.type === LocationMethods.Store ? 'store' : delivery.id,
        ...(delivery.type === LocationMethods.Store && {
          inventorySourceId: delivery.id,
        }),
        ...(!paidOnGateway && contacts),
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

interface GetCustomerOrdersParams {
  page: number
}

export const getCustomerOrders = async ({ page }: GetCustomerOrdersParams) => {
  const response = await axiosInstance.get<CustomerOrdersRaw>(
    urlJoin(
      CART_ROUTE,
      `?order%5BcreatedAt%5D=desc&page=${page}&itemsPerPage=${ORDERS_PER_PAGE}`
    ),
    {
      headers: {
        Accept: 'application/ld+json',
      },
    }
  )

  return parseRawCustomerOrdersData(response.data)
}

interface GetCustomerReturnsParams {
  page: number
}

export const getCustomerReturns = async ({
  page,
}: GetCustomerReturnsParams) => {
  const response = await axiosInstance.get<CustomerReturnsRaw>(
    urlJoin(
      RETURNS_ROUTE,
      `?page=${page}&itemsPerPage=${ORDERS_PER_PAGE}&order[submittedAt]=DESC`
    ),
    {
      headers: {
        Accept: 'application/ld+json',
      },
    }
  )

  return parseRawCustomerReturnsData(response.data)
}

export const getCustomerReturn = async (id?: string) => {
  if (!id) {
    throw new Error('Return id is not provided')
  }

  const response = await axiosInstance.get<ReturnItem>(
    urlJoin(RETURNS_ROUTE, id)
  )

  return response.data
}

// TODO refactor for consistency
export const updateCustomer = async (
  body: TCustomer & { customerId?: string | number }
) => {
  const { customerId, ...rest } = body
  await axiosInstance.put(`${CUSTOMER_ROUTE}/${customerId}`, rest)
}

export const updateCustomerNewsletterSettings = async (body: {
  customerId?: number
  subscribedToNewsletter?: boolean
}) => {
  const { customerId, subscribedToNewsletter } = body

  try {
    if (customerId != null) {
      await axiosInstance.put(urlJoin(CUSTOMER_ROUTE, String(customerId)), {
        subscribedToNewsletter,
      })
    } else {
      throw new Error('Customer ID was not provided.')
    }
  } catch (error) {
    throw new Error('Failed to update customer newsletter settings.')
  }
}

export const changeCustomerPassword = async ({
  customerId,
  ...body
}: ChangeCustomerPasswordParams) => {
  await axiosInstance.put(`${CUSTOMER_ROUTE}/${customerId}/password`, body)
}

export const getCustomerAddresses = async () => {
  const { token } = getAuthState()

  if (!token) {
    throw new Error('Unable to get customer token')
  }

  const response = await axiosInstance.get<TAddress[]>(
    `${CUSTOMER_ADDRESSES_ROUTE}`
  )

  return response.data
}

export const addCustomerAddresses = async (body: TAddressForm) => {
  const { token } = getAuthState()

  if (!token) {
    throw new Error('Unable to get customer token')
  }

  const response = await axiosInstance.post<TAddress>(
    CUSTOMER_ADDRESSES_ROUTE,
    body
  )

  return response.data
}

export const editCustomerAddress = async ({ id, ...body }: TAddress) => {
  const { token } = getAuthState()

  if (!token) {
    throw new Error('Unable to get customer token')
  }

  if (!id) {
    throw new Error('Address ID is required')
  }

  const response = await axiosInstance.put<TAddress>(
    urlJoin(CUSTOMER_ADDRESSES_ROUTE, String(id)),
    body
  )

  return response.data
}

export interface DeleteCustomerAddressParams {
  id?: number
}

export const deleteCustomerAddress = async ({
  id,
}: DeleteCustomerAddressParams) => {
  const { token } = getAuthState()

  if (!token) {
    throw new Error('Unable to get customer token')
  }

  if (!id) {
    throw new Error('Address ID is required')
  }

  await axiosInstance.delete(urlJoin(CUSTOMER_ADDRESSES_ROUTE, String(id)))
}

export interface SetDefaultAddressParams {
  id?: number
}

export const setDefaultAddress = async ({ id }: SetDefaultAddressParams) => {
  const { token, customerId } = getAuthState()

  if (!id) {
    throw new Error('Address ID was not provided')
  }

  if (!token || !customerId) {
    throw new Error('Customer must be logged in')
  }

  const response = await axiosInstance.put<TCustomer>(
    urlJoin(CUSTOMER_ROUTE, String(customerId)),
    {
      defaultAddress: id,
    }
  )

  return response.data
}

export const forgotPassword = async (body: ForgotPasswordBody) => {
  try {
    const response = await axiosInstance.post<undefined>(
      urlJoin(CUSTOMER_RESET_PASSWORD_ROUTE),
      {
        ...body,
      }
    )

    return response
  } catch (error) {
    throw error
  }
}

export const resetPassword = async (body: ResetPasswordBody, token: string) => {
  try {
    const response = await axiosInstance.patch<undefined>(
      urlJoin(CUSTOMER_RESET_PASSWORD_ROUTE, token),
      {
        ...body,
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    )

    return response
  } catch (error) {
    throw error
  }
}

export const verifyEmail = async (token: string) => {
  try {
    const response = await axiosInstance.patch<undefined>(
      urlJoin(CUSTOMER_VERIFY_EMAIL, token),
      {
        token,
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    )

    return response
  } catch (error) {
    throw error
  }
}

export const getWishlist = async () => {
  try {
    const { customerId } = getAuthState()

    if (!customerId) {
      return null
    }

    const response = await axiosInstance.get<Wishlist[]>(
      CUSTOMER_WISHLIST_ROUTE
    )

    return response?.data?.[0] ?? null
  } catch {
    return null
  }
}

export interface UpdateWishlistParams {
  id?: string | number
  body: WishlistUpdateBody
}

export const updateWishlist = async ({ id, body }: UpdateWishlistParams) => {
  if (!id) {
    throw new Error('Wishlist ID parameter is missing')
  }

  const response = await axiosInstance.patch<Wishlist>(
    urlJoin(CUSTOMER_WISHLIST_ROUTE, id.toString()),
    body,
    {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    }
  )

  return response?.data
}
