import { TFunction } from 'next-i18next'
import OmnivaLocationListIcon from '@components/icons/OmnivaLocationListIcon'
import { ECheckoutPlaces } from './locations'

export enum OrderRefundStatus {
  Cart = 'cart',
  New = 'new',
  Cancelled = 'cancelled',
  Fulfilled = 'fulfilled',
  PackageReceived = 'package_received',
  PackagePartiallyReceived = 'package_with_part_of_items_received',
  RepairedItemsSent = 'repaired_items_sent',
  ItemsReturnedToInventory = 'items_returned_to_inventory',
  ItemsPartiallyReturnedToInventory = 'items_partially_returned_to_inventory',
  Paid = 'PAID',
  Accepted = 'accepted',
  Received = 'received',
  Rejected = 'REJECTED',
}

export const ORDERS_PER_PAGE = 10

export enum OrderRefundOptions {
  TooBig = 'Prekė per didelė',
  TooSmall = 'Prekė per maža',
  NotMatch = 'Prekė neatitiko aprašymo/nuotraukos',
  Disliked = 'Prekė nepatiko',
  WrongItem = 'Gauta ne ta prekė',
  Defect = 'Gauta nekokybiška prekė',
  DLongRun = 'Defektas atsiradęs 2-jų metų laikotarpyje',
}

export const getReturnReasons = (t: TFunction) => [
  {
    value: OrderRefundOptions.TooBig,
    label: t('Item too big', { ns: 'order' }),
  },
  {
    value: OrderRefundOptions.TooSmall,
    label: t('Item too small', { ns: 'order' }),
  },
  {
    value: OrderRefundOptions.NotMatch,
    label: t('Item did not match description/photo', { ns: 'order' }),
  },
  {
    value: OrderRefundOptions.Disliked,
    label: t('Product not liked', { ns: 'order' }),
  },
  {
    value: OrderRefundOptions.WrongItem,
    label: t('Wrong item received', { ns: 'order' }),
  },
  {
    value: OrderRefundOptions.Defect,
    label: t('Defective item received', { ns: 'order' }),
  },
  {
    value: OrderRefundOptions.DLongRun,
    label: t('Defect occurred within 2 years', { ns: 'order' }),
  },
]

export const PICKUP_PROVIDERS = [
  {
    value: ECheckoutPlaces.Omniva,
    label: 'Omniva',
    icon: <OmnivaLocationListIcon width={24} />,
  },
  /*
    TODO: Restore DPD pickup provider after request (AR-1435)

    {
      value: ECheckoutPlaces.DPD,
      label: 'DPD',
      icon: (
        <CheckoutPlaceListItemIcon color={ECheckoutPlacesColors.DPD} width={24} />
      ),
    },
  */
]
