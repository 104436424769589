import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import * as https from 'https'
import { getRefreshToken } from '@services/customer/api'
import { LOGIN_ROUTE, REFRESH_TOKEN_ROUTE } from '@services/customer/routes'
import { clearAuthState, getAuthState, setAuthState } from '@lib/handlers/auth'

export const axiosInstance = axios.create({
  baseURL: process.env.ADEOWEB_SYLIUS_API_URL,
  headers: {
    Accept: 'application/json',
    // FIXME: Causes CORS error.
    // 'accept-language': 'lt_LT',
  },
  withCredentials: true,
  ...(process.env.NODE_ENV === 'development' && {
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  }),
})

function updateAxiosAuthHeader(accessToken: string) {
  axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`
}

createAuthRefreshInterceptor(
  axiosInstance,
  async (failedRequest) => {
    const { customerId, refreshToken: oldRefreshToken } = getAuthState()

    if (!oldRefreshToken) {
      clearAuthState()
      return Promise.reject()
    }

    const resp = await getRefreshToken({
      refreshToken: oldRefreshToken,
    })

    if (!resp) {
      clearAuthState()
      return Promise.reject()
    }

    const { refreshToken, token } = resp

    if (!token || !refreshToken) {
      clearAuthState()
      return Promise.reject()
    }

    setAuthState(customerId, token, refreshToken)
    updateAxiosAuthHeader(token)
    failedRequest.response.config.headers.Authorization = token

    return Promise.resolve()
  },
  {
    statusCodes: [401, 403],
  }
)

axiosInstance.interceptors.request.use((config) => {
  const excludedRoute = [REFRESH_TOKEN_ROUTE, LOGIN_ROUTE].includes(
    config?.url || ''
  )
  const { token } = getAuthState()

  if (token && !excludedRoute) {
    // @ts-ignore
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
})
