import { GUEST_CART_TOKEN, STORAGE_KEY_CUSTOMER } from '@lib/constants/storage'

export const getAuthState = (): {
  customerId?: string
  token?: string
  refreshToken?: string
} => {
  if (typeof window === 'undefined') {
    return {}
  }

  const data = localStorage.getItem(STORAGE_KEY_CUSTOMER)

  if (!data) {
    return {}
  }

  const customer = JSON.parse(data)

  return {
    customerId: customer?.customerId,
    token: customer?.token,
    refreshToken: customer?.refreshToken,
  }
}

export const setAuthState = (
  customerId?: string,
  token?: string,
  refreshToken?: string
): void => {
  localStorage.removeItem(GUEST_CART_TOKEN)
  return localStorage.setItem(
    STORAGE_KEY_CUSTOMER,
    JSON.stringify({
      customerId,
      token,
      refreshToken,
    })
  )
}

export const clearAuthState = (): void => {
  return localStorage.removeItem(STORAGE_KEY_CUSTOMER)
}
