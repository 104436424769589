export const CHECKOUT_INITIAL_PLACE: {
  [key in CountryCodes]: { latitude: number; longitude: number }
} = {
  LT: { latitude: 55.232377711618554, longitude: 23.925489594732216 },
  LV: { latitude: 56.9, longitude: 24.1 },
  EE: { latitude: 59.4, longitude: 24.7 },
}

export const CHECKOUT_INITIAL_ZOOM = 6.65052994992584
export const CHECKOUT_ON_ITEM_CLICK_ZOOM = 17

export const CONTACTS_HEADQUARTERS_PLACE = {
  lat: 55,
  lng: 23.9,
}

export enum ECheckoutPlaces {
  LP = 'LP',
  Shop = 'shop',
  DPD = 'dpd',
  Omniva = 'omniva',
}

export enum ECheckoutPlacesColors {
  Local = 'black',
  LP = '#FEDC00',
  DPD = '#F60030',
}

export enum CountryCodes {
  LT = 'LT',
  LV = 'LV',
  EE = 'EE',
}

export const COUNTRY_OPTIONS: Array<{
  value: CountryCodes
  label: string
  isDisabled?: boolean
}> = [
  { value: CountryCodes.LT, label: 'Lietuva' },
  { value: CountryCodes.LV, label: 'Latvija', isDisabled: true },
  { value: CountryCodes.EE, label: 'Estija', isDisabled: true },
]
