import { Genders } from '@lib/constants/genders'
import { Country } from '@lib/types/codegen/syliusSchemas'
import { CreateAccountFields } from './index'

export type TRefreshToken = {
  refreshToken?: string
  token?: string
}

export interface CreateAccountFormProps extends CreateAccountFields {
  acceptPrivacy: boolean
}

export interface ContactFields {
  firstname: string
  lastname: string
  ibanNumber: string
}

export interface ReturnItemFields {
  isSelected: boolean
  reason: string
  id: number
  shipmentId?: number
}

export enum LocationMethods {
  Store = 'store',
  Pickup = 'pickup',
}

export interface ReturnDeliveryPoint {
  type: LocationMethods
  id: string | number
}

export interface CreateReturnForm {
  allItemsSelect: boolean
  contacts: ContactFields
  items: Array<ReturnItemFields>
}
export type GenderOption = {
  value: Genders | string | undefined
  label: string
}

// TODO remove when BE fixes Country schema
export interface ICountry extends Omit<Country, 'provinces'> {
  provinces?: { code: string; name: string }[]
}

export interface PostitResponse {
  status: string
  success: true
  total: number
  page: {
    current: number
    total: number
  }
  data: Array<PostitAddress>
}

export interface PostitAddress {
  address?: string
  city?: string
  company?: string
  housing?: string
  mailbox?: string
  municipality?: string
  number?: string
  only_number?: string
  post?: string
  post_code?: string
  street?: string
}
